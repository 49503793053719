import React from 'react';
import { IonPage, IonCard, IonCardHeader, IonCardTitle } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import '../distribution/index.css';

const Warehouse = (props) => {

    return (
        <IonPage>
            <AppHeader headerText='Warehouse' redirectTo={ props.history.push } />
            <IonCard className='distribution-card'>
                <IonCardHeader>
                    <IonCardTitle class='dist-title'>
                        Will be available soon....
                    </IonCardTitle>
                </IonCardHeader>
            </IonCard>
        </IonPage>
    );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Warehouse));

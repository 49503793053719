import {
    getpo,AddPoItems,receivePO,updateqty
} from './service';
import { showLoaderAction, stopLoaderAction } from '../user/action';

/**
 * Action types
 */
export const actionTypes = {
    'PALLET_STATUS_RECEIVED': 'PALLET_STATUS_RECEIVED',
    'PALLET_CATEGORY_RECEIVED': 'PALLET_CATEGORY_RECEIVED',
    'PALLET_STORE_RECEIVED': 'PALLET_STORE_RECEIVED',
    'PALLETS_RECEIVED': 'PALLETS_RECEIVED',
    'PALLETS_UNMOUNT': 'PALLETS_UNMOUNT',
    'PALLETS_CLEAR': 'PALLETS_CLEAR',
    'PALLET_ITEMS_RECEIVED': 'PALLET_ITEMS_RECEIVED',
    'PALLET_BUILDERS_RECEIVED': 'PALLET_BUILDERS_RECEIVED',
    'PALLET_TYPES_RECEIVED': 'PALLET_TYPES_RECEIVED',
    'PALLET_ADD_ITEM_TO_LIST': 'PALLET_ADD_ITEM_TO_LIST',
    'PALLET_ADD_UPDATE_UNMOUNT': 'PALLET_ADD_UPDATE_UNMOUNT',
    'PALLET_SHIPPER_RECEIVED': 'PALLET_SHIPPER_RECEIVED',
    'CLEAR_PALLET_FORM_DATA': 'CLEAR_PALLET_FORM_DATA',
    'PALLET_FORM_DATA': 'PALLET_FORM_DATA',
    'BARCODE_SCAN': 'BARCODE_SCAN',
    'PO_QUANTIY_RECEIVED_ODOO': 'PO_QUANTIY_RECEIVED_ODOO',
    'VALIDATE_STORE_ID': 'VALIDATE_STORE_ID',
    'PRICE_SYNC_STATUS': 'PRICE_SYNC_STATUS'
};

export const setbarcodescan = (payload) => ({
    type: actionTypes.BARCODE_SCAN,
    payload
});

export const getPOIDdescriptionAction = (poId) => {
    console.log(poId,"uzzi data1")

    return (dispatch, getState) => {

        dispatch(showLoaderAction('barcodeScan'));

        console.log(poId,"data2")
        return getpo(poId)

            .then((response) => {
                console.log("res1",response);
                if(response.length){
                    dispatch(setbarcodescan(response));
                    return response;
                }

                return true;
            })
            .catch((error) => {
                
                dispatch(setbarcodescan());
                // dispatch(stopLoaderAction('getITODescription'));
                return false;
            });
    }
}

export const AddPoItemsAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('addPoItems'));

        return AddPoItems(data)
            .then((response) => {

                dispatch(stopLoaderAction('addPoItems'));
                return true;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('addPoItems'));
                return false;
            });
    }
}

export const receivedPOQuantityOdooAction = (payload) => ({
    type: actionTypes.PO_QUANTIY_RECEIVED_ODOO,
    payload
});

export const UpdateQtytoOdooAction = (data) => {
    console.log(data,"uodate data1")

    return (dispatch, getState) => {

        dispatch(showLoaderAction('poOdooQuantity'));
        console.log(data,"updadate_data2")

        return updateqty(data.orderline_id, data.qty_to_receive)
            .then((response) => {
                console.log("update_res",response);

                dispatch(stopLoaderAction('poOdooQuantity'));
                dispatch(receivedPOQuantityOdooAction(response));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('poOdooQuantity'));
                return false;
            });
    }
}

export const receivePOAction = (data) => {

    return (dispatch) => {

        dispatch(showLoaderAction('receivePO'));

        return receivePO(data)
            .then((response) => {

                dispatch(stopLoaderAction('receivePO'));
                return response;
            })
            .catch((error) => {

                dispatch(stopLoaderAction('receivePO'));
                return false;
            });
    }
}
